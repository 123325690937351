import React, { useEffect, Fragment } from "react";
import "./App.css";
import { BrowserRouter, Router, Route, Routes } from "react-router-dom";
import "./assets/css/all.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/responsive.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import Dashboard from "./components/Dashboard/Dashboard";
import KycList from "./components/Kyc/KycList";
import KycDetails from "./components/Kyc/KycDetails";
import UserList from "./components/Users/UserList";
import { createBrowserHistory } from "history";
import Login from "./components/Users/Login";
import CreateSaleAuction from "./components/CreateSaleAuction/CreateSaleAuction";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";

const history = createBrowserHistory({ window });

const MainApp = () => {
  const { auth, alerts } = useSelector((state) => {
    return { auth: state.auth, alerts: state.alerts };
  });
  useEffect(() => {
    if (alerts && Object.keys(alerts).length > 0) {
      alerts.map((x) => {
        return toast[x.alertType](x.msg);
      });
    }
  }, [alerts]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <BrowserRouter history={history}>
        {/*
        <Fragment> */}
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/kyc-list" element={<KycList />} />
            <Route
              exact
              path="/create-sale-auction"
              element={<CreateSaleAuction />}
            />

            <Route
              exact
              path="/user-list"
              element={<UserList history={history} />}
            />
            <Route
              exact
              path="/kyc-details/:id"
              element={<KycDetails history={history} />}
            />
          </Route>
        </Routes>
        {/* </Fragment> */}
      </BrowserRouter>
    </>
  );
};

export default MainApp;
