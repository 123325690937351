import { configureStore } from "@reduxjs/toolkit";
import * as slice from '../slice'

export const store = configureStore({
  reducer: {
    user: slice.userSlice,
    alerts: slice.alertSlice,
    auth: slice.authSlice,
    kyc: slice.kycSlice
  },
});
