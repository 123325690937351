import { ethers } from "ethers";
import { metaMaskClientCheck, connectWallet } from "./wallet";
// import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";
import { apiConstants } from "../../constants/api.constants";

import { commonService } from "../../service/common.service";

import nftAuctionJson from "./NFTAuction.json";
import erc721 from "./NFT.json";

const abi = nftAuctionJson.abi;
const NFTabi = erc721.abi;

let providerForView;

if (process.env.REACT_APP_ENV == "development") {
  providerForView = new ethers.providers.AlchemyProvider(
    "rinkeby",
    process.env.REACT_APP_ALCHEMY_RINKEBY_API
  );
} else if (process.env.REACT_APP_ENV == "production") {
  providerForView = new ethers.providers.AlchemyProvider(
    "homestead",
    process.env.REACT_APP_ALCHEMY_MAINNET_API
  );
}

const nftAuctionForView = new ethers.Contract(
  process.env.REACT_APP_MARKETPLACECONTRACT,
  abi,
  providerForView
);

// ====> Helper Functions.

async function getNftAuctionWithSigner(provider) {
  const nftAuction = new ethers.Contract(
    process.env.REACT_APP_MARKETPLACECONTRACT,
    abi,
    provider
  );

  return await nftAuction.connect(await provider.getSigner());
}

async function getNftContract(nftContractAddress) {
  try {
    return new ethers.Contract(nftContractAddress, NFTabi, providerForView);
  } catch (err) {
    console.log(err);
  }
}

async function waitForTxToComplete(tx) {
  const receipt = await tx.wait();

  if (receipt.status == 1) {
    // TODO: show the tx success
    console.log("Transaction: " + tx.hash + " was successful");
  } else {
    // TODO: show the tx error
    console.log("Transaction: " + tx.hash + " was unsuccessful");
  }
}

async function createAuctionSale(tx, nftContractAddress, tokenId, isAuction) {
  const receipt = await tx.wait();

  if (receipt.status == 1) {
    await commonService.withToken(apiConstants.CREATE_NFT_ACTION, {
      nftContract: nftContractAddress,
      tokenId: tokenId,
      isAuction: isAuction,
    });
  } else {
    alert("The transaction " + tx.hash + "failed. Please try again");
  }
}

async function updateAuctionSale(tx, nftContractAddress, tokenId) {
  const receipt = await tx.wait();

  if (
    receipt.status == 1 &&
    (await getAuctionData(nftContractAddress, tokenId).nftSeller) ==
      ethers.constants.AddressZero
  ) {
    await commonService.withToken(apiConstants.GET_NFT_UPDATE_DETAIL, {
      nftContract: nftContractAddress,
      tokenId: tokenId,
    });
  } else if (receipt.status != 1) {
    alert("The transaction " + tx.hash + "failed. Please try again");
  }
}

async function isSaleOrAuction(nftContractAddress, tokenId) {
  try {
    const nftAuctionDetail = await commonService.withOutToken(
      apiConstants.GET_NFT_DETAIL,
      {
        nftContract: nftContractAddress,
        tokenId: tokenId,
      }
    );

    return nftAuctionDetail.isAuction;
  } catch {
    const nftAuctionData = await nftAuctionForView.getNftAuctionDetails(
      nftContractAddress,
      tokenId
    );

    if (nftAuctionData.minPrice > 0) {
      return true;
    } else {
      return false;
    }
  }
}

async function isNftOwner(nftContractAddress, tokenId) {
  const nftContract = await getNftContract(nftContractAddress);
  const owner = await nftContract.ownerOf(tokenId);
  const currentSigner = await metaMaskClientCheck().getSigner();
  const currentAccount = await currentSigner.getAddress();

  if (owner != currentAccount) {
    return [
      false,
      "You are not the owner of this NFT. \nThe owner is: " +
        owner +
        "\nYour Address: " +
        currentAccount,
    ];
  }

  const approved = await nftContract.getApproved(tokenId);

  if (process.env.REACT_APP_MARKETPLACECONTRACT != approved) {
    const approval = await nftContract
      .connect(currentSigner)
      .approve(process.env.REACT_APP_MARKETPLACECONTRACT, tokenId);

    console.log("Approval tx ==> " + approval);

    const receipt = await approval.wait();

    // TODO: Show loading screen until receipt variable is defined

    if (receipt.status != 1) {
      return [false, "NFT Approval Failed"];
    }
  }

  return [true, ""];
}

async function isAuctionSaleOver(nftContractAddress, tokenId) {
  const nftAuctionData = await nftAuctionForView.getNftAuctionDetails(
    nftContractAddress,
    tokenId
  );

  if (nftAuctionData.nftSeller == ethers.constants.AddressZero) {
    return true;
  }

  return false;
}

// ====> End of Helper Functions.

// ====> view functions. Doesn't cost gas

export async function getAuctionData(nftContractAddress, tokenId) {
  try {
    const auctionData = await nftAuctionForView.getNftAuctionDetails(
      nftContractAddress,
      tokenId
    );

    console.log(auctionData);

    return auctionData;
  } catch (error) {
    console.log("error", error);
  }
}

export async function ownerOfNFT(nftContractAddress, tokenId) {
  try {
    const tx = await nftAuctionForView.ownerOfNFT(nftContractAddress, tokenId);

    console.log(tx);

    return tx;
  } catch (err) {
    console.log("Error: " + err);
  }
}

// ===> State change functions. Costs gas

export async function createDefaultNftAuction(
  nftContractAddress,
  tokenId,
  minPrice,
  buyNowPrice,
  feeRecipients, //send empty array if none
  feePercentages //send empty array if none
) {
  const nftAuctionWithSigner = await getNftAuctionWithSigner(
    await metaMaskClientCheck()
  );

  try {
    const [isOwner, reason] = await isNftOwner(nftContractAddress, tokenId);
    const saleOver = await isAuctionSaleOver(nftContractAddress, tokenId);

    if (saleOver) {
      if (isOwner) {
        const tx = await nftAuctionWithSigner.createDefaultNftAuction(
          nftContractAddress,
          tokenId,
          ethers.constants.AddressZero,
          await ethers.utils.parseEther(minPrice),
          await ethers.utils.parseEther(buyNowPrice),
          feeRecipients, //send empty array if none
          feePercentages //send empty array if none
        );

        console.log(tx);

        await createAuctionSale(tx, nftContractAddress, tokenId, true);
      } else {
        alert(reason);
      }
    } else {
      alert("The Auction/Sale of this NFT is already started");
    }
  } catch (err) {
    console.log("Error: " + err);
  }
}

export async function createSale(
  nftContractAddress,
  tokenId,
  buyNowPrice,
  whitelistedBuyer, // Will be used for direct sale. if none send ethers.constants.AddressZero
  feeRecipients, //send empty array if none
  feePercentages //send empty array if none
) {
  const nftAuctionWithSigner = await getNftAuctionWithSigner(
    await metaMaskClientCheck()
  );

  try {
    const [isOwner, reason] = await isNftOwner(nftContractAddress, tokenId);
    const saleOver = await isAuctionSaleOver(nftContractAddress, tokenId);

    if (saleOver) {
      if (isOwner) {
        console.log("Function is here ===>");

        const tx = await nftAuctionWithSigner.createSale(
          nftContractAddress,
          tokenId,
          ethers.constants.AddressZero,
          ethers.utils.parseEther(buyNowPrice),
          whitelistedBuyer,
          feeRecipients,
          feePercentages
        );

        console.log(tx);

        await createAuctionSale(tx, nftContractAddress, tokenId, false);
      } else {
        alert(reason);
      }
    } else {
      alert("The Auction/Sale of this NFT is already started");
    }
  } catch (error) {
    console.log("error", error);
  }
}

/// @dev send
/// auctionBidPeriod in terms of unix time
/// bidIncreasePercentage in multiple of 100s
/// max 1000 = 100%

export async function createNewNftAuction(
  nftContractAddress,
  tokenId,
  minPrice,
  buyNowPrice,
  auctionBidPeriod, //this is the time that the auction lasts until another bid occurs
  bidIncreasePercentage,
  feeRecipients,
  feePercentages
) {
  const nftAuctionWithSigner = await getNftAuctionWithSigner(
    await metaMaskClientCheck()
  );

  console.log(
    nftContractAddress,
    tokenId,
    minPrice,
    buyNowPrice,
    auctionBidPeriod, //this is the time that the auction lasts until another bid occurs
    bidIncreasePercentage,
    feeRecipients,
    feePercentages
  );

  try {
    const [isOwner, reason] = await isNftOwner(nftContractAddress, tokenId);
    const saleOver = await isAuctionSaleOver(nftContractAddress, tokenId);

    if (saleOver) {
      if (isOwner) {
        const tx = await nftAuctionWithSigner.createNewNftAuction(
          nftContractAddress,
          tokenId,
          ethers.constants.AddressZero,
          ethers.utils.parseEther(minPrice),
          ethers.utils.parseEther(buyNowPrice),
          auctionBidPeriod,
          bidIncreasePercentage,
          feeRecipients,
          feePercentages
        );

        console.log(tx);

        await createAuctionSale(tx, nftContractAddress, tokenId, true);
      } else {
        alert(reason);
      }
    } else {
      alert("The Auction/Sale of this NFT is already started");
    }
  } catch (err) {
    console.log("Error: " + err);
  }
}

export async function makeBid(nftContractAddress, tokenId, bidAmount) {
  const provider = await metaMaskClientCheck();

  const nftAuctionWithSigner = await getNftAuctionWithSigner(provider);

  console.log(nftContractAddress, tokenId, bidAmount);

  try {
    const auctionData = await getAuctionData(nftContractAddress, tokenId);

    if (auctionData.nftSeller != (await provider.getSigner().getAddress())) {
      const tx = await nftAuctionWithSigner.makeBid(
        nftContractAddress,
        tokenId,
        ethers.constants.AddressZero,
        0,
        { value: ethers.utils.parseEther(bidAmount) }
      );

      console.log(tx);

      await updateAuctionSale(tx, nftContractAddress, tokenId);
    } else {
      alert("Seller cannot bid on their own NFT");
    }
  } catch (error) {
    console.log(error);
  }
}

export async function makeCustomBid(
  nftContractAddress,
  tokenId,
  nftRecipient,
  bidAmount
) {
  const provider = await metaMaskClientCheck();

  const nftAuctionWithSigner = await getNftAuctionWithSigner(provider);

  try {
    const auctionData = await getAuctionData(nftContractAddress, tokenId);

    if (auctionData.nftSeller != (await provider.getSigner().getAddress())) {
      const tx = await nftAuctionWithSigner.makeCustomBid(
        nftContractAddress,
        tokenId,
        ethers.constants.AddressZero,
        0,
        nftRecipient,
        { value: ethers.utils.parseEther(bidAmount) }
      );

      console.log(tx);

      await updateAuctionSale(tx, nftContractAddress, tokenId);
    } else {
      alert("Seller cannot bid on their own NFT");
    }
  } catch (err) {
    console.log("Error: " + err);
  }
}

export async function settleAuction(nftContractAddress, tokenId) {
  const nftAuctionWithSigner = await getNftAuctionWithSigner(
    await metaMaskClientCheck()
  );

  try {
    const saleOver = await isAuctionSaleOver(nftContractAddress, tokenId);
    const [isOwner, reason] = await isNftOwner(nftContractAddress, tokenId);

    if (!saleOver) {
      if (isOwner) {
        const tx = await nftAuctionWithSigner.settleAuction(
          nftContractAddress,
          tokenId
        );

        console.log(tx);

        await updateAuctionSale(tx, nftContractAddress, tokenId);
      } else {
        alert(reason);
      }
    } else {
      alert("The sale is not listed to settle it");
    }
  } catch (err) {
    console.log("Error: " + err);
  }
}

export async function withdrawAuction(nftContractAddress, tokenId) {
  const nftAuctionWithSigner = await getNftAuctionWithSigner(
    await metaMaskClientCheck()
  );

  try {
    const saleOver = await isAuctionSaleOver(nftContractAddress, tokenId);

    const [isOwner, reason] = await isNftOwner(nftContractAddress, tokenId);

    if (!saleOver) {
      if (isOwner) {
        const tx = await nftAuctionWithSigner.withdrawAuction(
          nftContractAddress,
          tokenId
        );

        console.log(tx);

        await updateAuctionSale(tx, nftContractAddress, tokenId);
      } else {
        alert(reason);
      }
    } else {
      alert("The sale is not listed to withdraw it");
    }
  } catch (err) {
    console.log("Error: " + err);
  }
}

export async function withdrawBid(nftContractAddress, tokenId) {
  const nftAuctionWithSigner = await getNftAuctionWithSigner(
    await metaMaskClientCheck()
  );

  try {
    const saleOver = await isAuctionSaleOver(nftContractAddress, tokenId);

    if (!saleOver) {
      const tx = await nftAuctionWithSigner.withdrawBid(
        nftContractAddress,
        tokenId
      );

      console.log(tx);

      await waitForTxToComplete(tx);
    } else {
      alert("The sale is not listed to withdraw bid");
    }
  } catch (error) {
    console.log(error);
  }
}

export async function updateWhitelistedBuyer(
  nftContractAddress,
  tokenId,
  newWhitelistedBuyer
) {
  const nftAuctionWithSigner = await getNftAuctionWithSigner(
    await metaMaskClientCheck()
  );

  try {
    const saleOver = await isAuctionSaleOver(nftContractAddress, tokenId);
    const [isOwner, reason] = await isNftOwner(nftContractAddress, tokenId);
    const isAuction = await isSaleOrAuction(nftContractAddress, tokenId);

    if (!isAuction) {
      if (!saleOver) {
        if (isOwner) {
          const tx = await nftAuctionWithSigner.updateWhitelistedBuyer(
            nftContractAddress,
            tokenId,
            newWhitelistedBuyer
          );

          console.log(tx);

          await waitForTxToComplete(tx);
        } else {
          alert(reason);
        }
      } else {
        alert("The sale is not listed to update whitelisted buyers");
      }
    } else {
      alert("This Action is only available for Sale");
    }
  } catch (err) {
    console.log("Error: " + err);
  }
}

export async function updateMinimumPrice(
  nftContractAddress,
  tokenId,
  newMinPrice
) {
  const nftAuctionWithSigner = await getNftAuctionWithSigner(
    await metaMaskClientCheck()
  );

  try {
    const saleOver = await isAuctionSaleOver(nftContractAddress, tokenId);
    const [isOwner, reason] = await isNftOwner(nftContractAddress, tokenId);
    const isAuction = await isSaleOrAuction(nftContractAddress, tokenId);

    if (isAuction) {
      if (!saleOver) {
        if (isOwner) {
          const tx = await nftAuctionWithSigner.updateMinimumPrice(
            nftContractAddress,
            tokenId,
            ethers.utils.parseEther(newMinPrice)
          );

          console.log(tx);

          await waitForTxToComplete(tx);
        } else {
          alert(reason);
        }
      } else {
        alert("The sale is not listed to update minimum price");
      }
    } else {
      alert("This Action is only available for Auction");
    }
  } catch (err) {
    console.log("Error: " + err);
  }
}

export async function updateBuyNowPrice(
  nftContractAddress,
  tokenId,
  newBuyNowPrice
) {
  const nftAuctionWithSigner = await getNftAuctionWithSigner(
    await metaMaskClientCheck()
  );

  try {
    const saleOver = await isAuctionSaleOver(nftContractAddress, tokenId);

    const [isOwner, reason] = await isNftOwner(nftContractAddress, tokenId);

    if (!saleOver) {
      if (isOwner) {
        const tx = await nftAuctionWithSigner.updateBuyNowPrice(
          nftContractAddress,
          tokenId,
          ethers.utils.parseEther(newBuyNowPrice)
        );

        console.log(tx);

        await waitForTxToComplete(tx);
      } else {
        alert(reason);
      }
    } else {
      alert("The sale is not listed to update buyNow price");
    }
  } catch (err) {
    console.log("Error: " + err);
  }
}

export async function takeHighestBid(nftContractAddress, tokenId) {
  const nftAuctionWithSigner = await getNftAuctionWithSigner(
    await metaMaskClientCheck()
  );

  try {
    const saleOver = await isAuctionSaleOver(nftContractAddress, tokenId);

    const [isOwner, reason] = await isNftOwner(nftContractAddress, tokenId);

    if (!saleOver) {
      if (isOwner) {
        const tx = await nftAuctionWithSigner.takeHighestBid(
          nftContractAddress,
          tokenId
        );

        console.log(tx);

        await updateAuctionSale(tx, nftContractAddress, tokenId);
      } else {
        alert(reason);
      }
    } else {
      alert("The sale is not listed to update buyNow price");
    }
  } catch (err) {
    console.log("Error: " + err);
  }
}

export async function withdrawAllFailedCredits() {
  const nftAuctionWithSigner = await getNftAuctionWithSigner(
    await metaMaskClientCheck()
  );

  try {
    const tx = await nftAuctionWithSigner.withdrawAllFailedCredits();

    console.log(tx);

    await waitForTxToComplete(tx);
  } catch (err) {
    console.log("Error: " + err);
  }
}
