import { ethers } from "ethers";
import { metaMaskClientCheck, connectWallet } from "./wallet";
import { useDispatch } from "react-redux";

import erc721 from "./NFT.json";
import erc721Default from "./NFT_NEW_WEALTH.json";
import { apiConstants } from "../../constants/api.constants";

import { commonService } from "../../service/common.service";
import { ownerOfNFT } from "./marketplaceContract";

const NFTabi = erc721.abi;
const NFTabiDefault = erc721Default.abi;

const provider = new ethers.providers.Web3Provider(window.ethereum);

async function getNftContract(nftContractAddress) {
  try {
    if (process.env.REACT_APP_NFT_CONTRACT_ADDRESS == nftContractAddress) {
      return new ethers.Contract(nftContractAddress, NFTabiDefault, provider);
    } else {
      return new ethers.Contract(nftContractAddress, NFTabi, provider);
    }
  } catch (err) {
    console.log("Error: " + err);
  }
}

// ===> View functions. Doesn't costs gas

export async function getUri(nftContractAddress, tokenId) {
  try {
    const nftContract = getNftContract(nftContractAddress);

    return await nftContract.tokenURI(tokenId);
  } catch (err) {
    console.log(err);
  }
}

export async function getOwner(nftContractAddress, tokenId) {
  try {
    const nftContract = getNftContract(nftContractAddress);

    return await nftContract.ownerOf(tokenId);
  } catch (err) {
    console.log(err);
  }
}

// ===> End View functions. Doesn't costs gas

// ===> State change functions. Costs gas

export async function mintItem(itemDetails) {
  metaMaskClientCheck();

  try {
    const collectionData = await commonService.withToken(
      apiConstants.GET_COLLECTION_BY_ID,
      {
        id: itemDetails.collectionDetails,
      }
    );

    const nftContract = await getNftContract(collectionData.deployed_address);
    const owner = await nftContract.owner();

    if (owner != provider.getSigner()) {
      console.log(
        "Warn User that they don't own the collection contract and the owner of the collection is: " +
          owner
      );
    }

    const tx = await nftContract
      .connect(provider.getSigner())
      .createNFtToken(itemDetails.metadata);

    const receipt = await tx.wait();

    const event = receipt.events.find((event) => event.event === "Transfer");

    const [from, to, tokenId] = event.args;

    console.log(from, to, tokenId);

    if (receipt.status == 1 && from == ethers.constants.AddressZero) {
      await commonService.withToken(
        apiConstants.UPDATE_COLLECTION_DETALS_BYID,
        {
          itemId: itemDetails._id,
          minted: true,
          tokenId: tokenId,
        }
      );
    } else {
      console.log("Unable to to mint the item as NFT. Please try again");
    }
  } catch (err) {
    console.log("Error: " + err);
  }
}

// ===> End of State change functions. Costs gas
