import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiConstants } from "../constants/api.constants";
import { commonService } from '../service/common.service';
import { setAlert } from '../slice/alertSlice';

export const getKyc = createAsyncThunk(
  apiConstants.CREATE_KYC,
  async (data, { dispatch, rejectWithValue }) => {

    return commonService.withToken(apiConstants.GET_KYC, data).then(async (response) => {
      if (response.data.success) {
        return response.data;
      } else {
        let result = await errorHandler(response, dispatch)
        return result;
      }

    }).catch(async (err) => {
      let result = await errorHandler(err.response, dispatch)
      rejectWithValue(err)
      return result
    })

  }
);

export const approveKyc = createAsyncThunk(
  apiConstants.APPROVE_KYC,
  async (data, { dispatch }) => {

    return commonService.withToken(apiConstants.APPROVE_KYC, data).then(async (response) => {
      if (response.data.success) {
        dispatch(setAlert({ msg: 'Kyc Approved', alertType: 'success', timeout: 6000 }))
        return response.data.success;
      } else {
        let result = await errorHandler(response, dispatch)
        return result
      }
    }).catch(async (err) => {
      let result = await errorHandler(err.response, dispatch)
      return result;

    })

  }
);

export const rejectKyc = createAsyncThunk(
  apiConstants.CREATE_KYC,
  async (data, { dispatch, rejectWithValue }) => {

    return commonService.withToken(apiConstants.GET_KYC, data).then(async (response) => {
      if (response.data.success) {
        dispatch(setAlert({ msg: 'Kyc Rejected', alertType: 'success', timeout: 6000 }))
        return response.data.success;
      } else {
        let result = await errorHandler(response, dispatch)
        rejectWithValue(result)
      }
    }).catch(async (err) => {
      let result = await errorHandler(err.response, dispatch)
      rejectWithValue(result)
    })

  }
);

const errorHandler = async (response, dispatch) => {
  try {
    if (response) {
      let message = '';
      if (Array.isArray(response.data.errors)) {
        let arr = response.data.errors.map((x, index) => {
          if (index !== response.data.errors.length) {
            return x.msg + ','
          }
          else { return x.msg }
        })
        message = arr;
        return message;
      }
      else {
        if (response.data && response.data.msg) {
          message = response.data.msg
          return message
        }
      }

      dispatch(setAlert({ msg: JSON.stringify(message), alertType: 'error', timeout: 6000 }))
      return message
    }
  }
  catch (err) {
    console.log("======= Error in errorHandler() kycSlice ========", err)
  }
}

const initialState = {
  value: [],
  status: "",
};

export const kycSlice = createSlice({
  name: "kyc",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getKyc.fulfilled]: (state, action) => {
      state.value = action.payload;
      state.status = "fetched";
    },
    [rejectKyc.fulfilled]: (state, action) => {
      state.value = action.payload;
      state.status = "Kyc Rejected";
    },
    [approveKyc.fulfilled]: (state, action) => {
      state.value = action.payload;
      state.status = "Kyc Approved";
    },
  },
});

export default kycSlice.reducer;
