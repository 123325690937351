import React, { useState } from "react";
import Footer from "../Common/Footer";
import Navbar from "../Common/Navbar";
import Sidebar from "../Common/Sidebar";
import { Table, Button, Row, Col } from "react-bootstrap";
import {
  createNewNftAuction,
  createSale,
  getAuctionData,
  withdrawAuction,
  settleAuction,
  takeHighestBid,
  updateWhitelistedBuyer,
  updateBuyNowPrice,
  updateMinimumPrice,
} from "../blockchain/marketplaceContract";
import { getOwner, getUri } from "../blockchain/itemMint";
import CreateSaleModal from "./CreateSaleModal";
import SaleAuctionModal from "./SaleAuctionModal";
import UpdateBuyNow from "./UpdateBuyNowModal";
import UpdateMinPriceModal from "./UpdateMinPriceModal";
import UpdateWhitelistedAddressModal from "./UpdateWhitelistedAddressModal";

const AddressZero = "0x0000000000000000000000000000000000000000";

const CreateSaleAuction = () => {
  const [showAuction, setShowAuction] = useState(false);
  const [showSale, setShowSale] = useState(false);
  const [updateBuyNowShow, setUpdateBuyNowShow] = useState(false);
  const [updateMinPriceShow, setUpdateMinPriceShow] = useState(false);
  const [updateWhitelistedAddressShow, setUpdateWhitelistedAddressShow] =
    useState(false);

  const [nftContractAddress, setNftContractAddress] = useState("");
  const [token, setToken] = useState("");

  const list = [
    {
      nftContractAddress: "0x691aE20b40b1d61CB12a16F108a24802B5C21D4b",
      tokenId: 4,
    },
  ];

  const handleSaleClick = (nftContractAddress, tokenId) => {
    setShowSale(true);
    setNftContractAddress(nftContractAddress);
    setToken(tokenId);
  };

  const handleWithdrawClick = async (nftContractAddress, tokenId) => {
    const withdrawData = await withdrawAuction(nftContractAddress, tokenId);
    console.log("out", withdrawData);
  };

  const handleSettleClick = async (nftContractAddress, tokenId) => {
    const settleData = await settleAuction(nftContractAddress, tokenId);
    console.log("out", settleData);
  };

  const handleTakeHighestBidClick = async (nftContractAddress, tokenId) => {
    const takeHighestBidData = await takeHighestBid(
      nftContractAddress,
      tokenId
    );
    console.log("out", takeHighestBidData);
  };

  const handleUpdateWhitelistedClick = async (
    nftContractAddress,
    tokenId,
    newWhitelistedAddress
  ) => {
    setUpdateWhitelistedAddressShow(true);
    setNftContractAddress(nftContractAddress);
    setToken(tokenId);

    const whitelistedAddress = await updateWhitelistedBuyer(
      nftContractAddress,
      tokenId,
      newWhitelistedAddress
    );
    console.log("out", whitelistedAddress);
  };

  const handleUpdateBuyNowClick = async (
    nftContractAddress,
    tokenId,
    newBuyNowPrice
  ) => {
    setUpdateBuyNowShow(true);
    setNftContractAddress(nftContractAddress);
    setToken(tokenId);
    const updatedBuyNowPriceData = await updateBuyNowPrice(
      nftContractAddress,
      tokenId,
      newBuyNowPrice
    );
    console.log("out", updatedBuyNowPriceData);
  };

  const handleUpdateMinClick = async (
    nftContractAddress,
    tokenId,
    newMinPrice
  ) => {
    setUpdateMinPriceShow(true);
    setNftContractAddress(nftContractAddress);
    setToken(tokenId);
  };

  const handleAuctionClick = async (nftContractAddress, tokenId) => {
    setNftContractAddress(nftContractAddress);
    setToken(tokenId);
    setShowAuction(true);
  };

  const items = [];

  for (let i = 1; i <= 300; i++) {
    // const auth = JSON.parse(sessionStorage.getItem("user"));

    items.push(
      <tr>
        <td></td>

        <td>{list[0].nftContractAddress}</td>
        <td>{i}</td>
        <td></td>
        <td>
          <Row>
            <Col>
              <span className="font-weight-bold">Create Functions</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant="primary"
                style={{ margin: "5px" }}
                onClick={() => handleSaleClick(list[0].nftContractAddress, i)}
              >
                Create Sale
              </Button>
              {/* </Col>
            <Col> */}
              <Button
                variant="primary"
                onClick={() =>
                  handleAuctionClick(list[0].nftContractAddress, i)
                }
              >
                Create Auction
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <span className="font-weight-bold">End Functions</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant="primary"
                style={{ margin: "5px" }}
                onClick={() =>
                  handleWithdrawClick(list[0].nftContractAddress, i)
                }
              >
                Withdraw Listing
              </Button>
              {/* </Col>
            <Col> */}
              <Button
                variant="primary"
                style={{ margin: "5px" }}
                onClick={() => handleSettleClick(list[0].nftContractAddress, i)}
              >
                Settle Listing
              </Button>
              {/* </Col>
            <Col> */}
              <Button
                variant="primary"
                style={{ margin: "5px" }}
                onClick={() =>
                  handleTakeHighestBidClick(list[0].nftContractAddress, i)
                }
              >
                Take Highest Bid
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <span className="font-weight-bold">Update Functions</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant="primary"
                style={{ margin: "5px" }}
                onClick={() =>
                  handleUpdateWhitelistedClick(list[0].nftContractAddress, i)
                }
              >
                Update Whitelisted Buyer
              </Button>
              {/* </Col>
            <Col> */}
              <Button
                variant="primary"
                style={{ margin: "5px" }}
                onClick={() =>
                  handleUpdateBuyNowClick(list[0].nftContractAddress, i)
                }
              >
                Update Buy Now Price
              </Button>
              {/* </Col>
            <Col> */}
              <Button
                variant="primary"
                style={{ margin: "5px" }}
                onClick={() =>
                  handleUpdateMinClick(list[0].nftContractAddress, i)
                }
              >
                Update Min Price
              </Button>
            </Col>
          </Row>
        </td>
      </tr>
    );
  }

  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Sale/Auction List</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">list</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Record</h3>
                  </div>
                  <div className="card-body">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th style={{ width: "10px" }}>#</th>
                          <th>NFT Contract Address</th>
                          <th>Token Id</th>
                          <th>Listed</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items}
                        {/* {
                                                   list.map((data,index)=>{
                                                       return(
                                                        <tr key={index}>
                                                            <td></td>

                                                            <td>{data.nftContractAddress}</td>
                                                            <td>{data.tokenId}</td>
                                                            <td>
                                                            <Button variant="primary" style={{margin:"5px"}}>
                                                                Sale
                                                            </Button>
                                                            <Button variant="primary">
                                                                Auction
                                                            </Button>
                                                            </td>
                                                            </tr>
                                                       )
                                                   })
                                                }
                                               */}
                      </tbody>
                    </Table>
                  </div>
                  <div className="card-footer clearfix">
                    <ul className="pagination pagination-sm m-0 float-right">
                      <li className="page-item">
                        <a className="page-link" href="#">
                          &laquo;
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          1
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          2
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          3
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          &raquo;
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <CreateSaleModal
          showSale={showSale}
          setShowSale={setShowSale}
          nftContractAddress={nftContractAddress}
          tokenId={token}
        />
        <SaleAuctionModal
          showAuction={showAuction}
          setShowAuction={setShowAuction}
          nftContractAddress={nftContractAddress}
          tokenId={token}
        />
        <UpdateBuyNow
          setUpdateBuyNowShow={setUpdateBuyNowShow}
          updateBuyNowShow={updateBuyNowShow}
          nftContractAddress={nftContractAddress}
          tokenId={token}
        />
        <UpdateMinPriceModal
          setUpdateMinPriceShow={setUpdateMinPriceShow}
          updateMinPriceShow={updateMinPriceShow}
          nftContractAddress={nftContractAddress}
          tokenId={token}
        />
        <UpdateWhitelistedAddressModal
          setUpdateWhitelistedAddressShow={setUpdateWhitelistedAddressShow}
          updateWhitelistedAddressShow={updateWhitelistedAddressShow}
          nftContractAddress={nftContractAddress}
          tokenId={token}
        />
      </div>
      <Footer />
    </>
  );
};

export default CreateSaleAuction;
