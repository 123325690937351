export const apiConstants = {

    // KYC Api's
    GET_KYC: '/kyc/getKyc',
    APPROVE_KYC: '/kyc/approveKyc',
    REJECT_KYC: '/kyc/rejectKyc',
    GET_USER_KYC: '/kyc/kycDetails',


    // user api's
    GET_USER: '/admin/getAllUser',
    GET_ADMIN_DASHBOARD: '/admins/getAdminDashboard',

    // Admin Login

    ADMIN_LOGIN: '/admin/adminLogin',
    ADMIN_LOGOUT: '/admin/adminLogout',

    //nft apis here
    CREATE_NFT_ACTION: "/nftAuction/createNftAuction",
  GET_NFT_BY_COLLECTION_NAME: "/nftAuction/getNftByCollectionName",
  GET_NFT_DETAIL: "/nftAuction/getNftDetail",
  GET_ALL_NFT_UNSOLD: "/nftAuction/getAllUnsoldNft",
  GET_NFT_UPDATE_DETAIL: "/nftAuction/updateNftDetail",
}