import axios from 'axios';

export const commonService = {
    withToken,
    withOutToken,
    withTokenPut,
    handleResponse,
    withTokenAndFormData,
    withTokenAndFormDataDD,
    getDataWithToken,
    getDataWithoutToken
};


const url = process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_BASE_API;

//-- It's common function for using the token
function withToken(apiName, data) {
    let tokenObj = JSON.parse(sessionStorage.getItem('user')).accessToken;

    return axios({
        method: 'POST',
        url: `${url + apiName}`,
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${tokenObj}` },
        data: data
    }).then((handleResponse) => {
        return handleResponse;
    });
};

//-- It's common function for using the token with Put method
function withTokenPut(apiName, data) {
    let tokenObj = JSON.parse(sessionStorage.getItem('user')).accessToken
    return axios({
        method: 'PUT',
        url: `${url + apiName}`,
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${tokenObj}` },
        data: data
    }).then((handleResponse) => {

        return handleResponse;
    });
};

//-- It's common function for using without token
function withOutToken(apiName, data) {
    return axios({
        method: 'POST',
        url: `${url + apiName}`,
        data: data
    }).then(handleResponse);
};

//-- It's common function for using with form data
function withTokenAndFormData(apiName, data) {

    let filesInput = new FormData();
    // filesInput.append("passport", data.passport);
    // filesInput.append("idCard", data.idCard);
    let keys = Object.keys(data);
    let values = Object.values(data)
    keys.forEach((x, index) => {
        if(x && x.type && x.type == "file"){
            filesInput.append('files', {x : values[index].value})
        }
        filesInput.append(x, values[index])
    })

    let tokenObj = JSON.parse(sessionStorage.getItem("user")).accessToken
    return axios({
        method: 'POST',
        url: `${url + apiName}`,
        headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${tokenObj}` },
        // files: filesInput,
        data: filesInput
    }).then((handleResponse) => {
        return handleResponse;
    });
};

//-- It's common function for using with form data
function withTokenAndFormDataDD(apiName, data) {
    let tokenObj = JSON.parse(sessionStorage.getItem("user")).accessToken
    return axios({
        method: 'POST',
        url: `${url + apiName}`,
        headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${tokenObj}` },
        data: data
    }).then((handleResponse) => {
        return handleResponse;
    });
};

//-- get data
function getDataWithToken(apiName, userData) {

    let token = JSON.parse(sessionStorage.getItem('user')).accessToken;
    return axios({
        method: 'GET',
        url: `${url + apiName}`,
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        data: userData
    }).then((handleResponse) => {
        return handleResponse;
    });
}

//-- get data
function getDataWithoutToken(apiName, userData) {
    return axios({
        method: 'GET',
        url: `${url + apiName}`,
        headers: { 'Content-Type': 'application/json' },
        data: userData
    }).then((handleResponse) => {
        return handleResponse;
    });
}


function handleResponse(response) {

    if (response.status === 200 || response.status === 201) {
        return response;
    }
    else {
        const error = response;
        return Promise.reject(error);
    }

}