import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getKyc } from '../../slice/kycSlice';
import { useDispatch, useSelector } from "react-redux";
import Footer from '../Common/Footer';
import Navbar from '../Common/Navbar';
import Sidebar from '../Common/Sidebar';

const KycDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [kycState,setKycState] = useState('')
    const { kycData } = useSelector((state) => { return ({ kycData: state.kyc }) });

    useEffect(() => {
        if (id) {
            dispatch(getKyc({ user_id: id }))
        }
    }, [])

    useEffect(() => {
        console.log("kyc data -----------",kycData)
        if (kycData && Object.keys(kycData).length > 0) {
            if (kycData.status && kycData.value) {
                if(kycData.value.user){
                    setKycState(kycData.value.user)
                }

            }
        }
    }, [kycData])


    return (
        <>
            <Navbar />
            <Sidebar />
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Kyc Details</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Kyc Details</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">

                    <div className="card card-solid">
                        <div className="card-body pb-0">
                            <div className="row d-flex align-items-stretch">
                                <div className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
                                    <div className="card bg-light">
                                        <div className="card-header text-muted border-bottom-0">
                                            Digital Strategist
                                        </div>
                                        <div className="card-body pt-0">
                                            <div className="row">
                                                <div className="col-7">
                                                    <h2 className="lead"><b>User Name</b></h2>
                                                    <p className="text-muted text-sm"><b>Email: </b> {kycState && kycState.email ? kycState.email : ''} </p>
                                                    <ul className="ml-4 mb-0 fa-ul text-muted">
                                                        <li className="small"><span className="fa-li"><i className="fas fa-lg fa-building"></i></span> KYC Status:{kycState && kycState.kycStatus ? kycState.kycStatus : ''}</li>
                                                    </ul>
                                                </div>
                                                <div className="col-5 text-center">
                                                    <img src="../../dist/img/user1-128x128.jpg" alt="" className="img-circle img-fluid" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <div className="text-right">                                              
                                                <a href="#" className="btn btn-sm btn-primary">
                                                    <i className="fas fa-user"></i> View Profile
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>

                </section>
            </div>
            <Footer />
        </>
    )
}

export default KycDetails;