import React, { useState } from "react";
import { ethers } from "ethers";
import { useDispatch } from "react-redux";
import { Modal, Button, InputGroup, Input, FormControl } from "react-bootstrap";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import { createNewNftAuction } from "../blockchain/marketplaceContract";

const SaleAuctionModal = (props) => {
  const { showAuction, setShowAuction, tokenId, nftContractAddress } = props;
  console.log(showAuction);
  const [minPrice, setMinPrice] = useState("");
  const [buyNowPrice, setBuyNowPrice] = useState("");
  const [auctionBidPeriod, setAuctionBidPeriod] = useState(new Date());
  const [increaseBidPrice, setIncreaseBidPrice] = useState("");

  const [error, setError] = useState(false);

  const submitHandler = async () => {
    let feeRecipients = [];
    let feePercentages = [];

    let epochTime = Math.floor(new Date(auctionBidPeriod) / 1000)
    const newNftData = await createNewNftAuction(
      nftContractAddress,
      tokenId,
      minPrice,
      buyNowPrice,
      epochTime, //this is the time that the auction lasts until another bid occurs
      increaseBidPrice,
      feeRecipients,
      feePercentages
    );
    console.log(newNftData);
    setShowAuction(false);
  };

  const handleClose = () => {
    setShowAuction(false);
  };

  const handleDatePicker = (data) => {
    console.log(Math.floor(new Date(data) / 1000));
    setAuctionBidPeriod(data);
  };
  return (
    <>
      <Modal show={showAuction}>
        <Modal.Header>
          <Modal.Title>Sale Auction</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <form>
          <Modal.Body>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Enter Min Price"
                aria-describedby="basic-addon2"
                onChange={(e) => setMinPrice(e.target.value)}
                value={minPrice}
              />
              <InputGroup.Text id="basic-addon2">ETH</InputGroup.Text>
            </InputGroup>

            {error ? (
              <label className="text-danger">
                Bid amount must be grater than current bid amount
              </label>
            ) : (
              ""
            )}
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Enter Buy Now Price"
                aria-describedby="basic-addon2"
                onChange={(e) => setBuyNowPrice(e.target.value)}
                value={buyNowPrice}
              />
              <InputGroup.Text id="basic-addon2">ETH</InputGroup.Text>
            </InputGroup>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                className="form-control"
                label="Enter Auction Bid Period"
                value={auctionBidPeriod}
                onChange={handleDatePicker}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            <InputGroup className="mb-3">
              <FormControl
                placeholder="Enter Bid Increase Percentage"
                aria-describedby="basic-addon2"
                onChange={(e) => setIncreaseBidPrice(e.target.value)}
                value={increaseBidPrice}
              />
              <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
            </InputGroup>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={submitHandler}>
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default SaleAuctionModal;
