import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiConstants } from "../constants/api.constants";
import { setAlert } from './alertSlice';
import { authentication } from './authSlice'
import { commonService } from '../service/common.service';

const url = process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_BASE_API;

export const logout = createAsyncThunk(
  apiConstants.ADMIN_LOGOUT,
  async (data, { dispatch, rejectWithValue }) => {

    commonService.getDataWithToken(apiConstants.ADMIN_LOGOUT, data).then(response => {
      sessionStorage.removeItem('user')
      dispatch(authentication({ value: '', isLoggein: false }))
      dispatch(setAlert({ msg: 'Logged out successfully', alertType: 'success', timeout: 2000 }))
      return response.data.success;

    }).catch(async (err) => {

      let result = await errorHandler(err.repsonse)
      dispatch(setAlert({ msg: JSON.stringify(result), alertType: 'error', timeout: 2000 }))
      rejectWithValue(err)

    })

  }
);

export const updateProfile = createAsyncThunk(
  apiConstants.UPDATE_PROFILE,
  async (data, { dispatch, rejectWithValue }) => {

    commonService.withToken(apiConstants.UPDATE_PROFILE, data).then(response => {

      dispatch(setAlert({ msg: 'Profile updated successfully', alertType: 'success', timeout: 2000 }))
      return response.data;

    }).catch(async (err) => {

      let result = await errorHandler(err.response)
      dispatch(setAlert({ msg: JSON.stringify(result), alertType: 'error', timeout: 2000 }))
      rejectWithValue(err)

    })
  }
);

export const getAllUser = createAsyncThunk(
  apiConstants.GET_USER,
  async (data, { dispatch }) => {
    return commonService.getDataWithToken(apiConstants.GET_USER, data).then(response => {
      if(response.data.success){
        return response.data;
      }
    }).catch(async (err) => {
      let result = await errorHandler(err.response ,dispatch);
      return result;
    })
  }
);

export const login = createAsyncThunk(
  apiConstants.ADMIN_LOGIN,
  async (data, { dispatch, rejectWithValue }) => {

    return commonService.withOutToken(apiConstants.ADMIN_LOGIN, data).then(response => {
      if (response.data.success) {
        sessionStorage.setItem('user', JSON.stringify({ accessToken: response.data.accessToken, role: response.data.role }))
      }
      dispatch(authentication({ value: response.data, isLoggedin: true }))
      dispatch(setAlert({ msg: 'Logged in successfully', alertType: 'success', timeout: 2000 }))
      return response.data.success;

    }).catch(async (err) => {

      let result = await errorHandler(err.response)
      dispatch(setAlert({ msg: JSON.stringify(result), alertType: 'error', timeout: 2000 }))
      rejectWithValue(err)

    })

  }
);

const errorHandler = async (response,dispatch) => {
  try {
    if (response) {
      let arr = response.data.errors.map((x, index) => {
        if (index !== response.data.errors.length) {
          return x.msg + ','
        }
        else { return x.msg }
      })
      dispatch(setAlert({ msg: JSON.stringify(arr), alertType: 'error', timeout: 2000 }))
      return true
    }

  }
  catch (err) {
    console.log("======== Error in ErrorHandler user slice ========", err)
  }
}

const initialState = {
  value: "",
  status: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.value = action.payload;
      state.status = "created";
    },
    [logout.fulfilled]: (state, action) => {
      state.value = action.payload;
      state.status = "logged out";
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.value = action.payload;
      state.status = "Profile Updated";
    },
    [getAllUser.fulfilled]: (state, action) => {
      console.log("action or state===========",action,"state====",state)
      state.value = action.payload;
      state.status = "User Fetched";
    },
  },
});

export default userSlice.reducer;
