import { ethers } from "ethers";
import React, { useState } from "react";

const { detect } = require("detect-browser");

const browser = detect();

export async function connectWallet() {
  // console.log(await window.ethereum.request({ method: "eth_requestAccounts" }));
  //   setTimeout(
  //     async() {
  //       console.log(await window.ethereum.request({ method: "eth_requestAccounts" }));
  //     }
  //     .bind(this),
  //     3000
  // );
  const provider = new ethers.providers.Web3Provider(window.ethereum);

  console.log(await provider.send("eth_requestAccounts", []));
  return await provider.send("eth_requestAccounts", []);
  // return await window.ethereum.request({ method: "eth_requestAccounts" });
}

export async function signMessage() {
  const provider = metaMaskClientCheck();

  const signer = await provider.getSigner();

  let address = await signer.getAddress();

  let message = `Welcome to NFT!

Click to sign in and accept the NFT Terms of Service: https://nft.io/tos

This request will not trigger a blockchain transaction or cost any gas fees.

Your authentication status will reset after 24 hours.

Wallet address:
${address}

Nonce:
${await ethers.utils.hexlify(await ethers.utils.randomBytes(10))}`;

  // This array representation is 32 bytes long
  let messageBytes = ethers.utils.toUtf8Bytes(message);
  const signature = await signer.signMessage(messageBytes);

  return { address, signature, message };
}

function handleAccountChanged(accounts) {
  // TODO: Make a global variable to store the accounts[0] so that it can be
  // TODO: accessible from everywhere
  if (
    window.ethereum.networkVersion === "1" ||
    window.ethereum.networkVersion === "4"
  ) {
    sessionStorage.setItem("accountAddress", accounts[0]);
  } else {
    // TODO: Show alert to user for changing the network to specific network on
    // metamask
    alert("Please change network we are working with Ethereum mainet");
    window.location.reload();
  }
  return accounts[0];
}

function handleChainChanged(chainId) {
  if (chainId === "0x1" || chainId === "0x4") {
  } else {
    // TODO: Show alert to user for changing the network to specific network on
    // metamask
    alert("Please change network we are working with Ethereum mainet");
    window.location.reload();
  }
}

const isMetaMaskInstalled = () => {
  return Boolean(window.ethereum.isMetaMask !== "undefined");
};

export const metaMaskClientCheck = () => {
  if (!isMetaMaskInstalled()) {
    if (browser.name === "chrome") {
      if (
        window.confirm(
          "You don't have metamask extension Please click OK for download!"
        )
      ) {
        window.open(
          "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en",
          "_blank"
        );
      }
    } else if (browser.name === "firefox") {
      if (
        window.confirm(
          "You don't have metamask extension Please click OK for download!"
        )
      ) {
        window.open(
          "https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/",
          "_blank"
        );
      }
    }
    // TODO: Ask user to install metamask and provide metamask installation link
    // TODO: based on the browser
  } else {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    connectWallet();
    return provider;
  }
};

try {
  window.ethereum.on("accountsChanged", handleAccountChanged);
  window.ethereum.on("chainChanged", handleChainChanged);
} catch {}
