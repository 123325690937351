import React from "react";
import ReactDOM from "react-dom";
import MainApp from './App';
import { Provider } from "react-redux";
import { store } from "./store/store";

ReactDOM.render( 
  <React.StrictMode>
    <Provider store={store}><MainApp /></Provider>
  </React.StrictMode> , 
  document.getElementById('root')
	
);
