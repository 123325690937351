import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Footer from "../Common/Footer";
import Navbar from "../Common/Navbar";
import Sidebar from "../Common/Sidebar";
import { Button, Table } from "react-bootstrap";
import { getAllUser } from "../../slice/userSlice";
import { approveKyc, rejectKyc } from "../../slice/kycSlice";
import { useNavigate } from "react-router-dom";

var momentTimeZone = require("moment-timezone");
const timeZone = momentTimeZone.tz.setDefault("America/New_York");

const UserList = ({ history }) => {
  const [listing, setListing] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userList } = useSelector((state) => {
    return { userList: state.user };
  });

  useEffect(() => {
    console.log("in");
    dispatch(getAllUser());
  }, []);

  useEffect(() => {
    if (userList && Object.keys(userList).length > 0) {
      if (userList.status && userList.value && userList.value.users) {
        if (userList.value.users.length > 0) {
          setListing(userList.value.users);
        }
      }
    }
  }, [userList]);

  const kycHandler = (e, x) => {
    if (e) {
      if (e == 1) {
        dispatch(approveKyc(x._id));
      } else if (e == 2) {
        dispatch(rejectKyc(x._id));
      }
    }
  };

  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>User List</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">list</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Record</h3>
                  </div>
                  <div className="card-body">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th style={{ width: "10px" }}>#</th>
                          <th>Wallet Address</th>
                          <th>Name</th>
                          <th>Joined On</th>
                          <th>Role</th>
                          <th>Kyc Status</th>
                          <th>Edit</th>
                          <th>View</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listing &&
                          listing.map((x, index) => {
                            let kycPercent = 0;
                            let doj = "";
                            let progress = 0.5;

                            if (x.createdAt) {
                              doj = new Date(x.createdAt);
                            }
                            if (x && x.kycStatus) {
                              if (x.kycStatus == "Pending For Approval") {
                                kycPercent = 1;
                                progress = 50;
                              } else if (x.kycStatus == "Rejected By Admin") {
                                kycPercent = 2;
                                progress = 25;
                              }
                            }

                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  {x.wallet_address ? x.wallet_address : "-"}
                                </td>
                                <td>{x.name ? x.name : "-"}</td>
                                <td>
                                  {momentTimeZone.tz.guess() !== null &&
                                  momentTimeZone.tz.guess() !== undefined
                                    ? momentTimeZone(doj)
                                        .tz(momentTimeZone.tz.guess())
                                        .format("DD MMMM YYYY")
                                    : momentTimeZone(doj)
                                        .tz(timeZone)
                                        .format("DD MMMM YYYY")}
                                </td>
                                <td>{x.role ? x.role : "-"}</td>
                                <td>
                                  <div className="progress progress-xs">
                                    <div
                                      className={
                                        "progress-bar " +
                                        (kycPercent && kycPercent == 1
                                          ? "bg-success"
                                          : "bg-danger")
                                      }
                                      style={{ width: progress + "%" }}
                                    ></div>
                                  </div>
                                  <small
                                    className={
                                      "f-10 " +
                                      (kycPercent && kycPercent == 1
                                        ? "text-success"
                                        : "text-danger")
                                    }
                                  >
                                    <b>
                                      {x && x.kycStatus ? x.kycStatus : "-"}
                                    </b>
                                  </small>
                                </td>
                                <td>
                                  {kycPercent == 0 ? (
                                    <b className="text-muted">
                                      KYC not uploaded
                                    </b>
                                  ) : (
                                    <Button
                                      variant="primary"
                                      onClick={() => kycHandler(kycPercent, x)}
                                    >
                                      {x && x.kycStatus && x.kycStatus == 1
                                        ? "Approval"
                                        : "Reject"}
                                    </Button>
                                  )}
                                </td>
                                <td className="text-center">
                                  <i
                                    className="fas fa-eye"
                                    onClick={() => {
                                      navigate("/kyc-details/" + x._id);
                                    }}
                                  ></i>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                  <div className="card-footer clearfix">
                    <ul className="pagination pagination-sm m-0 float-right">
                      <li className="page-item">
                        <a className="page-link" href="#">
                          &laquo;
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          1
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          2
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          3
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          &raquo;
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default UserList;
