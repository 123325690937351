import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            <footer className="main-footer">
                <strong>Copyright &copy; 2014-2019 <Link to="#">NFT Admin</Link>.</strong>
                All rights reserved.
            </footer>
        </>
    )
}

export default Footer;