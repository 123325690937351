import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { login } from '../../slice/userSlice';


const Login = () => {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const { user, auth } = useSelector((state) => {
    return { user: state.user, auth: state.auth };
  });


  useEffect(() => {
    if (auth && Object.keys(auth).length > 0) {
      if (auth.isLoggedin && user.value) {
        navigate("/dashboard");
      }
    }
  }, [user]);

  const handleLogin = () => {
    let obj = {
      email,
      password
    }
    dispatch(login(obj))
  }
  return (
    <div className='hold-transition login-page'>
      <div className="login-box">
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">Sign in to start your session</p>

            <form>
              <div className="form-outline mb-4">
                <input type="email" id="form2Example1" className="form-control" onChange={(e) => setEmail(e.target.value)} />
                <label className="form-label" for="form2Example1">
                  Email address
                </label>
              </div>

              <div className="form-outline mb-4">
                <input type="password" id="form2Example2" className="form-control" onChange={(e) => setPassword(e.target.value)} />
                <label className="form-label" for="form2Example2">
                  Password
                </label>
              </div>

              <div className="row mb-4">
                <div className="col d-flex justify-content-center">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="form2Example31"
                      checked
                    />
                    <label className="form-check-label" for="form2Example31">
                      {" "}
                      Remember me{" "}
                    </label>
                  </div>
                </div>

                <div className="col">
                  <a href="#!">Forgot password?</a>
                </div>
              </div>

              <button type="button" className="btn btn-primary btn-block mb-4" onClick={handleLogin}>
                Sign in
              </button>

            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login;